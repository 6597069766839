import { ContextModalProps, ModalsProvider } from '@mantine/modals';
import { Text, Button } from '@mantine/core';

type HouseModalProps = { body: string }

export const HouseModal = ({ context, id, innerProps: { body } }: ContextModalProps<HouseModalProps>) => (
  <>
    {/* <Button compact fullWidth my="md" onClick={() => context.closeModal(id)}>
      Close modal
    </Button> */}
    {body}
  </>
);
